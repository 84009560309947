<script setup lang="ts">
import Chip from '@frontend/ui-kit/src/components/Base/Chip.vue';
import { useBookingStore } from '~/layers/booking/stores/bookingStore';
import { useNotificationStore } from '~/stores/alert/useNotificationStore';

const { t } = useI18n();
const bookingStore = useBookingStore();
const { bookingSessionEnd } = storeToRefs(bookingStore);
const notificationStore = useNotificationStore();

const isLoading = ref<boolean>(true);
let timer: ReturnType<typeof setInterval>;

const remainingTime = ref<number>(0);
const updateRemainingTime = () => {
    if (bookingSessionEnd.value) {
        const endDate = bookingSessionEnd.value.getTime();
        const now = Date.now();
        remainingTime.value = endDate - now; // Оставшееся время в миллисекундах
    }
};

onMounted(() => {
    isLoading.value = false;
    updateRemainingTime();

    timer = setInterval(() => {
        updateRemainingTime(); 
    }, 1000);
});

onBeforeUnmount(() => {
    clearInterval(timer);
});

const timerFormatted = computed(() => {
    const totalSeconds = Math.floor(remainingTime.value / 1000);
    const minutes = String(Math.floor(totalSeconds / 60)).padStart(2, '0');
    const seconds = String(totalSeconds % 60).padStart(2, '0');
    
    return `${minutes}:${seconds}`;
});

const showInfo = () => {
    const notificationObject = {
        title: t("booking.common.timer.title"),
        text: remainingTime.value > 0 ? t("booking.common.timer.processing") : t("booking.common.timer.over"),
    }
    notificationStore.showInformation(notificationObject);
};

</script>

<template>
    <header class="header">
        <div class="header__container">
            <div class="logo">
                <v-skeleton-loader 
                    v-if="isLoading"
                    color="neutral04" 
                    type="image" 
                    class="logo-loader"
                />
                <img v-else src="/images/logo.svg">
            </div>
            <div class="timer">
                <v-skeleton-loader 
                    v-if="isLoading"
                    color="neutral04" 
                    class="timer-loader"
                    type="chip, text" 
                />
                <template v-else>
                    <Chip
                        v-if="remainingTime > 0"
                        class="timer-chip"
                        color="black" 
                        :text="timerFormatted"
                    />
                    <div class="timer-text">{{ remainingTime > 0 ? $t("booking.common.timer.processing") : $t("booking.common.timer.over") }}</div>
                    <i class="ri-information-fill timer-icon" @click="showInfo"></i>
                </template>
            </div>
        </div>
    </header>
</template>

<style scoped lang="scss">
.header {
    width: 100%;

    &__container {
        padding: 20px 64px;
        width: 100%;
        max-width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include responsive('mobile') {
            padding: 20px 24px;
        }
    }

    .logo {
        max-width: 125px;

        &-loader {
            width: 125px;

            :deep(.v-skeleton-loader__bone) {
                margin: 0;
                width: 100%;
                height: 20px;
            }
        }
    }
}

.timer {
    display: flex;
    align-items: center;
    gap: 16px;

    &-text {
        font-size: 12px;
        line-height: 125%;
        letter-spacing: -0.01em;
        color: $color-neutral;
        max-width: 230px;

        @include responsive("mobile-small") {
            display: none;
        }
    }

    &-icon {
        display: none;
        font-size: 24px;
        color: $color-neutral;

        @include responsive("mobile-small") {
           display: block;
        }
    }

    &-loader {
        width: 300px;
        display: flex;
        height: 32px;
        background-color: transparent !important;

        @include responsive("mobile-small") {
            width: 140px;
        }

        :deep(.v-skeleton-loader__bone) {
            margin: 0;

            &.v-skeleton-loader__text {
                @include responsive("mobile-small") {
                    margin-left: 12px;
                    max-width: 40px;
                }
            }

        }
    }
}
</style>

